import * as bootstrap from 'bootstrap'
import { ConsoleCart, render_serial_cart, empty_cart } from './cart'
import './favourites.js'
import { initShoppingLists, deleteShoppingList } from './shoppinglist'
import { initAlpineCart } from './alpinecart'

var _ = require('lodash')

function loadSideMenu(page_id) {
    if (!page_id) {
        page_id = 0
    }
    $.ajax({
        url: '/menu/' + page_id + '/' + page_id + '/menu_core/',
        success: function (result) {
            $('#mainNavMenu').html(result)
        },
    })
}

function loadAjaxMenu(event) {
    let a = event.target.closest('a')

    $.ajax({
        url: a.dataset.mcUrl,
        success: function (result) {
            $('#' + a.dataset.mcTarget).html(result)
        },
    })
    // event.stopPropagation();
    // event.preventDefault();
}

function loadFilterMenu(event) {
    let filters = document.querySelector('#facetsFull')
    let mobile_filters = document.querySelector('#productFilterView')
    mobile_filters.innerHTML = filters.innerHTML
    Array.from(mobile_filters.getElementsByTagName('input')).forEach(function (
        ele,
        idx
    ) {
        ele.id = 'ovf-' + ele.id
    })
    Array.from(mobile_filters.getElementsByTagName('label')).forEach(function (
        ele,
        idx
    ) {
        let forInput = ele.getAttribute('for')
        ele.setAttribute('for', 'ovf-' + forInput)
    })
    bindFilterForm()
}

function handle_navbar_overflowing() {
    try {
        let first_item_element = $('.mc-top-navbar-ul').find('.nav-item').first();
        if (!first_item_element) {
            $('#topnav-overflow-menu').addClass('d-none')
            return
        }

        let first_item_y = first_item_element[0].offsetTop
        let isOverflowing = false
        $('.mc-top-navbar-ul')
            .find('.nav-item')
            .each(function () {
                let mcPageIdValue = $(this).data('mc-page-id')
                let overflowItem = $(
                    '.nav-item-overflow[data-mc-page-id="' +
                        mcPageIdValue +
                        '"]'
                )
                if (this.offsetTop == first_item_y) {
                    $(this).removeClass('invisible')
                    $('#mc-header-full').removeClass('header-overflowing')
                    $(overflowItem).addClass('d-none')
                } else {
                    $(this).addClass('invisible')
                    $(overflowItem).removeClass('d-none')
                    $('#mc-header-full').addClass('header-overflowing')
                    isOverflowing = true
                }
            })

        if (isOverflowing) {
            $('#topnav-overflow-menu').removeClass('d-none')
        } else {
            $('#topnav-overflow-menu').addClass('d-none')
        }
    } catch (err) {
        console.log(err)
    }
}

document.addEventListener('htmx:afterRequest', function(evt) {
    // After we load a dropdown menu trigger this
    // function so we can hide the visible items
    // from the overflow menu
    handle_navbar_overflowing()
 })

var resizeHandler = _.debounce(function () {
    handle_navbar_overflowing()
    _.delay(handle_navbar_overflowing, 100)
}, 50)

var orientationHandler = _.debounce(function () {
    handle_navbar_overflowing()
    _.delay(handle_navbar_overflowing, 100)
}, 50)

function initStickyNav() {
    document.addEventListener(
        'scroll',
        _.debounce(function () {
            if (window.scrollY > 100) {
                document
                    .querySelector('#mc-header-full')
                    .classList.add('scroll-nav')
                document
                    .querySelector('#mc-header-above-full')
                    .classList.add('scroll-nav')
                if (window.scrollY > 200) {
                    document.querySelector('.scrollTop').classList.add('d-flex')
                    document
                        .querySelector('.scrollTop')
                        .classList.remove('d-none')
                }
            } else if (window.scrollY < 50) {
                document
                    .querySelector('#mc-header-full')
                    .classList.remove('scroll-nav')
                document
                    .querySelector('#mc-header-above-full')
                    .classList.remove('scroll-nav')
                document.querySelector('.scrollTop').classList.add('d-none')
                document.querySelector('.scrollTop').classList.add('d-flex')
            }
        }, 10)
    )
}

function initDropdownAjax() {
    $('#mc-header-nav [data-bs-toggle="dropdown"]').on(
        'hide.bs.dropdown',
        (event) => {
            $('#' + event.target.dataset.mcTarget).html('')
        }
    )
}

function scrollToTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
}

function makeToast(message, type) {
    // make a new toast element and inject it into #toasts
    let toast = document.createElement('div')
    toast.className = 'toast'
    toast.setAttribute('role', 'alert')
    toast.setAttribute('aria-live', 'assertive')
    toast.setAttribute('aria-atomic', 'true')
    toast.setAttribute('data-bs-autohide', 'true')
    toast.setAttribute('data-bs-delay', '3000')
    toast.setAttribute('data-bs-animation', 'true')
    if (type) {
        toast.classList.add('text-bg-' + type)
    }
    let toastBody = document.createElement('div')
    toastBody.className = 'toast-body'
    toastBody.innerText = message
    toast.appendChild(toastBody)
    document.getElementById('toasts').appendChild(toast)
    let toastInstance = new bootstrap.Toast(toast)
    toastInstance.show()
}

function initPriceRange() {
    $('[data-mc-slider-target]').each(function () {
        $(this).off('change')
        let target = $(this).data('mc-slider-target')
        $('#' + target).off('change keyup')
        $('#' + target).on('change', function (e) {
            e.preventDefault()
            let val = $(this).val()
            $('[data-mc-slider-target=' + $(this).attr('id') + ']').val(val)
        })
        $(this).on('input', function (e) {
            e.preventDefault()
            let val = $(this).val()
            let target = $(this).data('mc-slider-target')
            $('#' + target).val(val)
        })
    })
}

window.MightyJS = {
    loadSideMenu,
    loadAjaxMenu,
    render_serial_cart,
    loadFilterMenu,
    initStickyNav,
    initDropdownAjax,
    initShoppingLists,
    empty_cart,
    deleteShoppingList,
    scrollToTop,
    resizeHandler,
    orientationHandler,
    initPriceRange,
    initAlpineCart,
    makeToast,
}

window.console_cart = new ConsoleCart()
